var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-detail-modal"},[_c('b-modal',{attrs:{"id":_vm.MODAL_IDS.ORDER_ITEM_DETAIL_MODAL,"size":"xl","centered":"","scrollable":"","modal-class":"order-detail"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Chi tiết của đơn hàng: "+_vm._s(_vm.customerName)+" ")]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.onPrint}},[_vm._v(" "+_vm._s(_vm.$t('PrintProducingBill'))+" ")]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"variant":"info"},on:{"click":_vm.onActivityLogClick}},[_vm._v(" "+_vm._s(_vm.$t('ActivityLog'))+" ")])],1)]},proxy:true}])},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.headers,"rows":_vm.orderDetails,"styleClass":"vgt-table","select-options":{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Dòng đã chọn',
        clearSelectionText: 'Reset'
      }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'status')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.displayStatus(props.row))+" "),_c('div',{staticClass:"inline"},[_c('b-dropdown',{staticClass:"pa-0",attrs:{"variant":"link","toggle-class":"text-decoration-none pa-0 ml-1  ","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-right mr-25",attrs:{"id":"updateStatusBtn","icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_vm._l((_vm.orderDetailStatusOptions),function(statusOption){return [(props.row.status !== statusOption.value)?_c('b-dropdown-item',{key:statusOption.value,attrs:{"disabled":_vm.isDisableStatusOption(props.row, statusOption.value)},on:{"click":function($event){return _vm.updateStatus(props.row.id, statusOption.value)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(statusOption.text))+" ")])]):_vm._e()]})],2)],1)]):(props.column.field === 'total')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.formatCurrency(props.row[props.column.field]))+" ")]):(props.column.field === 'pricePerUnit')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.formatCurrency(props.row[props.column.field]))+" ")]):(
            props.column.field === 'processingTypeName2' &&
            !props.row[props.column.field] &&
            !props.row[props.column.field] !== ''
          )?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):(props.column.field === 'serviceName')?_c('span',{},[_c('span',{staticClass:"text-align-left",domProps:{"innerHTML":_vm._s(_vm.getServiceName(props.row))}})]):_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(props.row[props.column.field]))])])]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('b-dropdown',{staticClass:"pr-2",attrs:{"variant":"link","dropdown":"","no-caret":"","text":"Đổi trạng thái","toggle-class":"pa-0 ml-1 text-bold","disabled":_vm.isDisableMultiChangeStatus}},[_vm._l((_vm.orderDetailStatusOptions),function(option){return [_c('b-dropdown-item',{key:'all' + option.value,attrs:{"disabled":_vm.isDisableStatusOptionMultiItems(option.value)},on:{"click":function($event){return _vm.updateStatusMultiItems(option.value)}}},[_c('span',[_vm._v(_vm._s(_vm.$t(option.text)))])])]})],2)],1)])],1),_c('ActivityLogModal',{ref:"activityLogRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }