import { Vue, Component, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BModal,
  BOverlay
} from 'bootstrap-vue';
import {
  OrderNamespace,
  OrderGetter,
  OrderAction,
  ORDER_STATE_NAMESPACE,
  OrderMutation,
  OrderState
} from '@/store/order/order.module-types';
import {
  OrderViewModel,
  OrderStatus,
  CutType,
  OrderDeliveryType,
  OrderDetailType,
  OrderFormularType,
  CustomerLevel,
  Gender,
  OrderDetailViewModel,
  MasterDataViewModel,
  CreateOrderDetailInput
} from '@/api/api';
import {
  convertCurrencyToNumber,
  enumToDropdownOptions,
  formatCurrency,
  formatDate,
  formatThousand
} from '@/utility/utils';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { quillEditor } from 'vue-quill-editor';
import { VueGoodTable } from 'vue-good-table';
import { mapActions, mapMutations, mapState } from 'vuex';
import { ACTIVITY_LOG_ENTITY, COMPANY_INVOICE_INFO } from '@/utility/constants';
import { PrintType } from '@/views/print/orderDetailPrint/OrderDetailPrint';
import AuthStorageService from '@/utility/account/auth.storage.service';
import {
  SETTING_STATE_NAMESPACE,
  SettingAction,
  SettingState
} from '@/store/setting/setting.module-types';
import ActivityLogModal from '@/components/activityLog/ActivityLogModal.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueGoodTable,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BModal,
    BOverlay,
    ActivityLogModal
  },
  computed: {
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData]),
    ...mapState(ORDER_STATE_NAMESPACE, [OrderState.companyInvoiceInfo])
  },
  methods: {
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData]),
    ...mapMutations(ORDER_STATE_NAMESPACE, [
      OrderMutation.setCompanyInvoiceInfo
    ])
  }
})
export default class OrderDetail extends Vue {
  @OrderNamespace.Getter(OrderGetter.order)
  order!: OrderViewModel;

  companyInvoiceInfo!: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  };

  $refs!: {
    activityLogRef: InstanceType<typeof BModal>;
  };

  orderId = '';
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  OrderDetailType = OrderDetailType;
  orderStatusOptions: DropdownOption[] = enumToDropdownOptions(OrderStatus);
  cutTypeOptions: DropdownOption[] = enumToDropdownOptions(CutType);
  OrderDeliveryType = OrderDeliveryType;
  CustomerLevel = CustomerLevel;
  OrderFormularType = OrderFormularType;
  formatCurrency = formatCurrency;
  formatThousand = formatThousand;
  formatDate = formatDate;
  companyInvoiceInfoModel: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  } | null = null;
  editingFields = {
    companyName: false,
    companyAddress: false,
    companyPhone: false
  };
  isLoading = true;

  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  hasFullPermission = AuthStorageService.isFullPermission();

  setCompanyInvoiceInfo!: (data) => void;

  get totalPrice(): number {
    let price = 0;
    this.order?.orderDetails?.forEach((s) => (price += s.total));
    const totalWithServices =
      convertCurrencyToNumber(this.order.shippingFee) +
      price -
      convertCurrencyToNumber(this.order.couponAmount);
    const total =
      totalWithServices + (this.order.vatAmount / 100) * totalWithServices;
    return total < 0 ? 0 : total;
  }

  get remainAmount(): number {
    return this.totalPrice - convertCurrencyToNumber(this.order.depositAmount);
  }

  get colSpanNumber(): number {
    return 6;
  }

  displayCutType(value) {
    return this.cutTypeOptions.find((x) => x.value === value)
      ? this.$t(
          this.cutTypeOptions.find((x) => x.value === value)?.text as string
        )
      : '';
  }

  mounted() {
    if (!this.masterData) {
      this.fetchMasterData();
    }

    this.companyInvoiceInfoModel = {
      companyAddress: this.companyInvoiceInfo.companyAddress,
      companyName: this.companyInvoiceInfo.companyName,
      companyPhone: this.companyInvoiceInfo.companyPhone
    };

    this.resetCompanyInvoiceInfoModel();
    localStorage.setItem(
      COMPANY_INVOICE_INFO,
      JSON.stringify(this.companyInvoiceInfo)
    );
  }

  resetCompanyInvoiceInfoModel() {
    if (this.order?.deliveryType === OrderDeliveryType.ByCustomer) {
      this.companyInvoiceInfoModel = {
        companyAddress:
          this.order.orderAddress ?? this.companyInvoiceInfo.companyAddress,
        companyName: this.companyInvoiceInfo.companyName,
        companyPhone: this.companyInvoiceInfo.companyPhone
      };
      return;
    }

    this.companyInvoiceInfoModel = {
      companyAddress: this.companyInvoiceInfo.companyAddress,
      companyName: this.companyInvoiceInfo.companyName,
      companyPhone: this.companyInvoiceInfo.companyPhone
    };
  }

  onPrint() {
    const newWindow = window.open(
      `/print-order/${this.order.id}?type=${PrintType.OrderDetail.toString()}`,
      '_blank',
      `width=1000,height=${window.innerHeight}`
    );
    if (newWindow === null) return;
  }

  changeCompanyInfo() {
    this.setCompanyInvoiceInfo({
      companyAddress: this.companyInvoiceInfoModel?.companyAddress ?? '',
      companyName: this.companyInvoiceInfoModel?.companyName ?? '',
      companyPhone: this.companyInvoiceInfoModel?.companyPhone ?? ''
    });

    localStorage.setItem(
      COMPANY_INVOICE_INFO,
      JSON.stringify(this.companyInvoiceInfo)
    );

    this.resetCompanyInvoiceInfoModel();
    this.editingFields = {
      companyName: false,
      companyAddress: false,
      companyPhone: false
    };
  }

  getServiceName(orderDetail: OrderDetailViewModel) {
    const productName = orderDetail.serviceName;
    if (
      orderDetail.categoryId &&
      orderDetail.categoryId === this.masterData.catalogueCategoryId
    ) {
      return `<div><div class='row mx-0'><div class='col-sm-7 px-0'>${orderDetail.serviceName} </div> <div class='col-sm-5 px-0'>- ${orderDetail.totalPage} Trang</div></div>
              <div class='row mx-0'><div class='col-sm-7 px-0'>- ${orderDetail.catalogueProductName}</div> <div class='col-sm-5 px-0'>- ${orderDetail.catalogueSizeName}</div></div>
              <div class='row mx-0'><div class='col-sm-7 px-0'>- ${orderDetail.polymeCoverTypeName}</div></div></div>`;
    }

    if (
      orderDetail.processingSettingModels &&
      orderDetail.processingSettingModels.length > 0
    ) {
      let productNameCell = `<div><div class='row mx-0'><div class='col-sm-6 px-0'>${productName}</div>`;

      orderDetail.processingSettingModels?.forEach((item) => {
        productNameCell += `<div class='col-sm-6 px-0'>- ${
          item?.processingSettingModelItems
            ? item.processingSettingModelItems[0].name
            : ''
        } </div>`;
      });
      return productNameCell + '</div>';
    }

    return `<div>
    <div class='row mx-0'>
    <div class='col-sm-6 px-0'>
      ${productName}
    </div>
      <div class='col-sm-6 px-0'>
    ${
      orderDetail.polymeCoverTypeName
        ? '- ' + orderDetail.polymeCoverTypeName
        : ''
    }</div>
    <div class='col-sm-6 px-0'>
    ${
      orderDetail.processingTypeName
        ? '- ' + orderDetail.processingTypeName
        : ''
    }</div></div>
    ${
      orderDetail.processingTypeName2
        ? '- ' + orderDetail.processingTypeName2
        : ''
    }</div>`;
  }

  @Watch('order')
  updateLoading() {
    if (this.order) {
      this.isLoading = false;
    }
  }

  getCompanyAddress() {
    let address = this.companyInvoiceInfo.companyAddress;
    if (this.order?.deliveryType === OrderDeliveryType.ByCustomer) {
      address =
        this.order.customerAddress ?? this.companyInvoiceInfo.companyAddress;
    }

    return this.truncateAddress(address);
  }

  truncateAddress(value) {
    return value.replace(
      /Địa chỉ 1|Địa chỉ 2|Văn phòng|Cơ sở 1:|Xưởng in:|: /gi,
      ''
    );
  }

  isCatalogueItem(orderDetail: CreateOrderDetailInput) {
    return orderDetail.categoryId === this.masterData.catalogueCategoryId;
  }

  onActivityLogClick() {
    this.$refs.activityLogRef.openModal(
      ACTIVITY_LOG_ENTITY.ORDER,
      this.order.id,
      true
    );
  }
}
