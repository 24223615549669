import {
  CatalogueOrderMaterial,
  CatalogueSizeOption,
  CustomerViewModel,
  OrderDetailViewModel,
  SelectModelOfString
} from './../../../api/api';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormSelectOption,
  BFormCheckbox,
  BModal,
  BSpinner,
  BOverlay
} from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import {
  CreateOrderDetailInput,
  CreateOrderInput,
  CustomerLevel,
  Gender,
  OrderDeliveryType,
  OrderFormularType,
  PriceListViewModel
} from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import {
  convertCurrencyToNumber,
  enumToDropdownOptions,
  formatDate
} from '@/utility/utils';
import ApiClientFactory from '@/api/apiClientFactory';
import { PriceGetter, PriceNamespace } from '@/store/price/price.module-types';
import { formatCurrency } from '@/utility/utils';
import { ModelSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import OrderDetailTable from './OrderDetailTable.vue';
import vSelect from 'vue-select';
import { mapActions, mapState } from 'vuex';
import {
  OrderState,
  ORDER_STATE_NAMESPACE
} from '@/store/order/order.module-types';
import {
  CustomerAction,
  CustomerState,
  CUSTOMER_STATE_NAMESPACE
} from '@/store/customer/customer.module-types';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import { ValidationProvider } from 'vee-validate';
import OrderDetailForm from './OrderDetailForm.vue';
import {
  EMPLOYEE_STATE_NAMESPACE,
  EmployeeAction,
  EmployeeState
} from '@/store/employee/employee.module-types';
import { ACTIVITY_LOG_ENTITY } from '@/utility/constants';
import ActivityLogModal from '@/components/activityLog/ActivityLogModal.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    CustomLabel,
    BFormSelectOption,
    BFormRadio,
    BFormCheckbox,
    BSpinner,
    ModelSelect,
    OrderDetailTable,
    vSelect,
    BOverlay,
    OrderDetailForm,
    ActivityLogModal
  },
  computed: {
    ...mapState(ORDER_STATE_NAMESPACE, [OrderState.orderMaterial]),
    ...mapState(CUSTOMER_STATE_NAMESPACE, [CustomerState.customers]),
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData]),
    ...mapState(EMPLOYEE_STATE_NAMESPACE, [EmployeeState.saleEmployees])
  },
  methods: {
    ...mapActions(CUSTOMER_STATE_NAMESPACE, [CustomerAction.fetchCustomers]),
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData]),
    ...mapActions(EMPLOYEE_STATE_NAMESPACE, [EmployeeAction.fetchSaleEmployees])
  }
})
export default class OrderForm extends Vue {
  @PriceNamespace.Getter(PriceGetter.priceList)
  priceList!: PriceListViewModel[];

  @Prop({ type: Number, required: true })
  orderId!: number;

  isEditting = false;
  isLoading = false;

  $refs!: {
    orderForm: InstanceType<typeof ValidationProvider>;
    orderDetailForm: InstanceType<any>;
    modal: InstanceType<typeof BModal>;
    tableRef: InstanceType<typeof OrderDetailTable>;
    pricePerUnitRef: InstanceType<typeof BFormInput>;
    activityLogRef: InstanceType<typeof BModal>;
  };
  OrderDeliveryType = OrderDeliveryType;
  genderOptions: DropdownOption[] = enumToDropdownOptions(Gender);
  orderDeliveryTypeOptions: DropdownOption[] = enumToDropdownOptions(
    OrderDeliveryType
  );
  CustomerLevel = CustomerLevel;
  customerLevelOptions: DropdownOption[] = enumToDropdownOptions(CustomerLevel);
  orderInput: CreateOrderInput = this.getDefaultInput();
  isSubmitting = false;
  isOnBehalf = false;

  //Vuex
  // products!: ProductViewModel[];
  // fetchProducts!: () => Promise<void>;
  // productCategories!: ProductCategoryViewModel[];
  // fetchProductCategories!: () => Promise<void>;
  customers!: CustomerViewModel[];
  fetchCustomers!: () => Promise<void>;
  // masterData!: MasterDataViewModel;
  // fetchMasterData!: () => Promise<void>;

  saleEmployees!: SelectModelOfString[];
  // Type the mapped fetchPosts action.
  fetchSaleEmployees!: () => Promise<void>;

  formatDate = formatDate;

  priceReferences: OrderDetailViewModel[] = [];

  defaultServiceInputValue: CreateOrderDetailInput = new CreateOrderDetailInput(
    {
      //type: OrderDetailType.Product,
      serviceId: 0,
      machining: '',
      cutType: '',
      quantity: 0,
      width: 0,
      height: 0,
      pricePerUnit: 0,
      note: '',
      area: 0,
      categoryId: 0,
      depositAmount: 0,
      includeVAT: false,
      polymeCoverTypeId: '',
      processingTypeId2: '',
      processingTypeId: '',
      totalPrice: 0,
      vatAmount: 0,
      couponCode: '',
      fileName: '',
      sample: undefined,
      totalPage: 0
    } as CreateOrderDetailInput
  );
  orderDetailInput: CreateOrderDetailInput = {
    ...this.defaultServiceInputValue
  } as CreateOrderDetailInput;
  selectedServiceToEditIndex?: number = undefined;
  OrderFormularType = OrderFormularType;

  employeeOptions: DropdownOption[] = [];
  daysForDeliveryOptions: DropdownOption[] = [
    { value: 0, text: '_today' },
    { value: 1, text: '1day' },
    { value: 2, text: '_2days' },
    { value: 3, text: '_3days' }
  ];
  usingReferncePrice = false;

  mounted() {
    //this.orderId = parseInt(this.$route.params.id);
  }

  @Watch('orderId', { immediate: true })
  orderIdUpdateHandle() {
    if (this.orderId && this.orderId != 0) {
      this.isEditting = true;
      this.loadOrder();
    }
  }

  @Watch('saleEmployees', { immediate: true })
  loadSaleEmployees() {
    this.employeeOptions = this.saleEmployees?.map(
      (x) =>
        ({
          value: x.id,
          text: x.name
        } as DropdownOption)
    );
    if (
      (!this.orderInput.employeeId || this.orderInput.employeeId == '') &&
      this.employeeOptions?.length > 0
    ) {
      this.orderInput.employeeId = this.employeeOptions[0].value;
    }
  }

  formatCurrency = formatCurrency;

  loadOrder() {
    this.isLoading = true;
    const client = new ApiClientFactory().orderClient();
    client.get(this.orderId).then((val) => {
      this.orderInput.needBill = val.needBill;
      if (val.needBill) {
        this.orderInput.companyAddress = val.companyAddress;
        this.orderInput.companyName = val.companyName;
        this.orderInput.companyTaxCode = val.companyTaxCode;
      }

      this.orderInput.discountAmount = val.couponAmount;
      this.orderInput.customerAddress = val.orderAddress;
      this.orderInput.customerId = val.customerId;
      this.orderInput.customerLevel = val.customerLevel;
      this.orderInput.customerName = val.customerName ?? '';
      this.orderInput.customerNameOnBehalf = val.customerNameOnBehalf;
      this.orderInput.customerPhoneNumber = val.customerPhone ?? '';
      this.orderInput.customerPhoneNumberOnBehalf = val.customerPhoneOnBehalf;
      this.orderInput.daysForDelivery = val.deliveryDate;
      this.orderInput.deliveryType = val.deliveryType;
      this.orderInput.depositAmount = val.depositAmount;
      this.orderInput.email = val.email;
      this.orderInput.employeeId = val.employeeId;
      this.orderInput.gender = val.gender;
      this.orderInput.isSaveTemplate = val.isSaveTemplate;
      this.orderInput.needInstruction = val.needInstruction;
      this.orderInput.needTest = val.needTest;
      this.orderInput.otherRequirement = val.otherRequirement;
      this.orderInput.shippingFee = val.shippingFee;
      this.orderInput.vatAmount = val.vatAmount;

      this.isOnBehalf =
        this.orderInput.customerNameOnBehalf != '' &&
        this.orderInput.customerNameOnBehalf != undefined &&
        this.orderInput.customerNameOnBehalf != null;
      console.log(!this.orderInput.customerNameOnBehalf);
      console.log(this.orderInput.customerNameOnBehalf);

      if (val.orderDetails && val.orderDetails.length > 0) {
        const catalogueProductItems: CatalogueOrderMaterial[] = [];

        val.orderDetails.forEach((s) => {
          const orderDetail = {
            id: s.id,
            area: s.area,
            height: s.height,
            includeVAT: s.includeVAT,
            pricePerUnit: s.pricePerUnit,
            quantity: s.quantity,
            totalPrice: s.total,
            serviceId: s.serviceId,
            categoryId: s.categoryId,
            vatAmount: s.vatAmount,
            width: s.width,
            fileName: s.fileName,
            note: s.note,
            polymeCoverTypeId: s.polymeCoverTypeId,
            polymeCoverTypeName: s.polymeCoverTypeName,
            processingTypeId2: s.processingTypeId2,
            processingTypeName2: s.processingTypeName2,
            processingTypeId: s.processingTypeId,
            processingTypeName: s.processingTypeName,
            sample: s.sample,
            status: s.status,
            totalPage: s.totalPage,
            catalogueProductItemId: s.catalogueProductId,
            processingSettings: s.processingSettingModels
          } as CreateOrderDetailInput;
          this.orderInput.createOrderDetails.push(orderDetail);

          catalogueProductItems.push({
            catalogueProductItemId: s.catalogueProductId,
            catalogueProductItemName: s.catalogueProductName,
            sizeOptions: [
              {
                name: s.catalogueSizeName,
                height: s.height,
                width: s.width
              } as CatalogueSizeOption
            ]
          } as CatalogueOrderMaterial);
        });

        this.$refs.orderDetailForm.catalogueProductItems = catalogueProductItems;
      }
      this.isLoading = false;
    });
  }

  getDeliveryDate() {
    return this.formatDate(
      Date.now(),
      'dd/MM/yyyy',
      parseInt(this.orderInput.daysForDelivery.toString())
    );
  }

  getDefaultInput() {
    return new CreateOrderInput({
      gender: Gender.Male,
      customerName: '',
      customerPhoneNumber: '',
      customerAddress: '',
      customerNameOnBehalf: '',
      customerPhoneNumberOnBehalf: '',
      needInstruction: false,
      needTest: false,
      needBill: false,
      companyName: '',
      companyAddress: '',
      companyTaxCode: '',
      otherRequirement: '',
      deliveryType: OrderDeliveryType.ByCompany,
      createOrderDetails: [],
      daysForDelivery: 0,
      customerLevel: CustomerLevel.Ordinary,
      orderFormularType: OrderFormularType.ByPrice,
      employeeId: undefined,
      email: '',
      isFromPublicSite: false,
      vatAmount: 0,
      discountAmount: 0,
      isSaveTemplate: false,
      shippingFee: 0,
      depositAmount: 0
    });
  }

  create() {
    this.isLoading = true;

    this.orderInput.createOrderDetails.forEach((s) => {
      s.quantity = convertCurrencyToNumber(s.quantity);
      s.pricePerUnit = convertCurrencyToNumber(s.pricePerUnit);
      s.totalPrice = convertCurrencyToNumber(s.totalPrice);
      s.vatAmount = convertCurrencyToNumber(s.vatAmount);
    });
    const client = new ApiClientFactory().orderClient();
    client
      .create({
        ...this.orderInput,
        discountAmount: convertCurrencyToNumber(this.orderInput.discountAmount),
        depositAmount: convertCurrencyToNumber(this.orderInput.depositAmount),
        shippingFee: convertCurrencyToNumber(this.orderInput.shippingFee),
        vatAmount: convertCurrencyToNumber(this.orderInput.vatAmount),
        createOrderDetails: [
          ...this.orderInput.createOrderDetails
        ] as CreateOrderDetailInput[]
      } as CreateOrderInput)
      .then(() => {
        this.$bvToast.toast('Thêm đơn hàng thành công', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });

        this.onBackHandle();
      })
      .catch((err) => {
        this.$bvToast.toast('Lỗi Khi Lưu', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  update() {
    this.isLoading = true;

    this.orderInput.createOrderDetails.forEach((s) => {
      s.quantity = convertCurrencyToNumber(s.quantity);
      s.pricePerUnit = convertCurrencyToNumber(s.pricePerUnit);
      s.totalPrice = convertCurrencyToNumber(s.totalPrice);
      s.vatAmount = convertCurrencyToNumber(s.vatAmount);
    });
    const client = new ApiClientFactory().orderClient();
    client
      .update(this.orderId, {
        ...this.orderInput,
        discountAmount: convertCurrencyToNumber(this.orderInput.discountAmount),
        depositAmount: convertCurrencyToNumber(this.orderInput.depositAmount),
        shippingFee: convertCurrencyToNumber(this.orderInput.shippingFee),
        vatAmount: convertCurrencyToNumber(this.orderInput.vatAmount),
        createOrderDetails: [
          ...this.orderInput.createOrderDetails
        ] as CreateOrderDetailInput[]
      } as CreateOrderInput)
      .then(() => {
        this.$bvToast.toast('Sửa đơn hàng thành công', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
        this.onBackHandle();
      })
      .catch((err) => {
        this.$bvToast.toast('Lỗi Khi Lưu', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  submit() {
    this.$refs.orderForm.validate().then((success) => {
      if (success) {
        if (this.isEditting === true) {
          this.update();
          return;
        }
        this.create();
      }
    });
  }

  onReset() {
    this.selectedServiceToEditIndex = undefined;
    this.orderDetailInput = {
      ...this.defaultServiceInputValue
    } as CreateOrderDetailInput;

    this.$refs.orderDetailForm.onReset();
  }

  async onCustomerNameChange(value: string) {
    if (value) {
      const customers = this.customers.filter(
        (s) => s.displayName?.toLowerCase() === value.toLowerCase()
      );
      if (customers && customers.length > 0) {
        this.orderInput.customerId = customers[0].id;
        this.orderInput.customerPhoneNumber = customers[0].phone ?? '';
        this.orderInput.customerLevel = customers[0].level as CustomerLevel;
        this.orderInput.email = customers[0].email;
        this.orderInput.customerAddress = customers[0].address;
        return;
      }

      this.orderInput.customerId = '';
      this.orderInput.customerPhoneNumber = '';
      this.orderInput.customerLevel = CustomerLevel.Ordinary;

      if (
        this.orderDetailInput.serviceId &&
        this.orderDetailInput.serviceId !== 0
      ) {
        this.isLoading = true;
        const clientApi = new ApiClientFactory();
        const priceReferences = await clientApi
          .orderClient()
          .getReferencePrices(
            this.orderInput.customerId,
            this.orderDetailInput.serviceId
          );
        let priceReference = priceReferences.find(
          (s) =>
            s.polymeCoverTypeId === this.orderDetailInput.polymeCoverTypeId &&
            s.processingTypeId === this.orderDetailInput.processingTypeId
        );
        if (
          this.orderDetailInput.processingTypeId2 &&
          this.orderDetailInput.processingTypeId2 !== ''
        ) {
          priceReference = priceReferences.find(
            (s) =>
              s.polymeCoverTypeId === this.orderDetailInput.polymeCoverTypeId &&
              s.processingTypeId === this.orderDetailInput.processingTypeId &&
              s.processingTypeId2 === this.orderDetailInput.processingTypeId2
          );
        }

        this.orderDetailInput.pricePerUnit = priceReference?.pricePerUnit ?? 0;
        this.usingReferncePrice = this.orderDetailInput.pricePerUnit !== 0;
        this.isLoading = false;
      }
    }
  }

  onBackHandle() {
    this.orderInput = this.getDefaultInput();
    this.onReset();
    this.isEditting = false;
    this.$emit('OnSubmitSuccess');
  }

  onActivityLogClick() {
    this.$refs.activityLogRef.openModal(
      ACTIVITY_LOG_ENTITY.ORDER,
      this.orderId,
      true
    );
  }

  onBehalfChecked(checked) {
    if (!checked) {
      this.orderInput.customerNameOnBehalf = '';
      this.orderInput.customerPhoneNumberOnBehalf = '';
    }
  }

  isAccessFromMobile() {
    const details = navigator.userAgent;

    /* Creating a regular expression
      containing some mobile devices keywords
      to search it in details string*/
    const regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
      it returns boolean value*/
    console.log('isAccessMobile: ', regexp.test(details));
    return regexp.test(details);
  }
}
